const getResourcesUrl = 'https://api.retool.com/v1/workflows/af3a173b-4c8d-4dad-af33-0c8820fb7d24/startTrigger?workflowApiKey=retool_wk_dc5cb335a38b4944b999bca3763df658';

const requestOptions = {
  method: 'POST',
};

let resources = {};

fetch(getResourcesUrl)
  .then(response => response.json()) 
  .then(data => {
    resources = data
  
    console.log(resources)

    const filterSection = document.getElementById("filter-section");
    const resourceCards = document.getElementById("resource-cards");

    // Function to create a card for a resource
    function createResourceCard(resource) {
      const card = document.createElement("div");
      card.classList.add("card");

      const thumbnail = document.createElement("img");
      thumbnail.classList.add("thumbnail");
      thumbnail.src = resource.image;
      card.appendChild(thumbnail);
   
      const description = document.createElement("p");
      description.textContent = resource.description;
      card.appendChild(description);

      const resourceLink = document.createElement("a");
      resourceLink.href = resource.url;
      resourceLink.textContent = "get resource";
      card.appendChild(resourceLink);

      const spacer = document.createElement("span");
      spacer.textContent = " | ";
      card.appendChild(spacer);


      const creatorLink = document.createElement("a");
      creatorLink.href = resource.creator_url;
      creatorLink.textContent = "creator";
      card.appendChild(creatorLink);

      const breakSection = document.createElement("br");
      card.appendChild(breakSection);
      const breakSection2 = document.createElement("br");
      card.appendChild(breakSection2);


      const coreTag = document.createElement("p");
      coreTag.textContent = resource.core;
      card.appendChild(coreTag);

      const curatedBy = document.createElement("span");
      curatedBy.textContent = "sims core curator: " + resource.curated_by;
      curatedBy.style = "font-style: italic;"
      card.appendChild(curatedBy);



      resourceCards.appendChild(card);
    }

    function createFilterChips() {
        const uniqueCores = [...new Set(resources.map((resource) => resource.core))];
        
        // Add "All" chip
        const allChip = document.createElement("button");
        allChip.classList.add("filter-chip");
        allChip.textContent = "👀 all";
        allChip.addEventListener("click", () => filterResources("👀 all"));
        filterSection.appendChild(allChip);
        
        uniqueCores.forEach((core) => {
          const chip = document.createElement("button");
          chip.classList.add("filter-chip");
          chip.textContent = core;
          chip.addEventListener("click", () => filterResources(core));
          filterSection.appendChild(chip);
        });
      }
  
      // Function to filter resources by core
      function filterResources(core) {
        resourceCards.innerHTML = "";
        if (core === "👀 all") {
          resources.forEach((resource) => createResourceCard(resource));
        } else {
          resources
            .filter((resource) => resource.core === core)
            .forEach((resource) => createResourceCard(resource));
        }
      }
  
      // Initialize the page
      createFilterChips();
      filterResources("👀 all"); // Display all resources by default
      
      // Hide the loader
      var loader = document.getElementById("loader");
      loader.classList.add('hide');

      // Show the bottom div
      var element = document.getElementById("bottom-div");
      element.classList.remove("hide-bottom-div");
  })
  .catch(error => {
    console.error('Error:', error)
  });